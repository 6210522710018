export default function TElement({ percent }) {
  const dynamicHeight = (295 * percent) / 100;
  return (
    <div className="relative w-[70px] h-[300px]">
      <div className="absolute top-0 left-0 border-[3px] w-[66px] h-0 border-border" />
      <div
        className={`absolute top-[0px] left-[32px] border-[3px] w-0 border-border`}
        style={{ height: `${dynamicHeight}px` }}
      />
    </div>
  );
}

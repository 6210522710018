import { publicAPI } from './axios';

export async function getQuestionAndAnswer() {
  try {
    const response = await publicAPI.get('/question_answer/');
    return response.data;
  } catch (error) {
    throw new Error('An unexpected error occurred');
  }
}

export async function setQuestionAndAnswer(props) {
  try {
    const response = await publicAPI.post('/question_answer/', props);
    return response.data;
  } catch (error) {
    throw new Error('An unexpected error occured');
  }
}

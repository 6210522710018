import { publicAPI } from './axios';

export async function getGameResult() {
  try {
    const response = await publicAPI.get('/game-result/');
    return response.data;
  } catch (error) {
    throw new Error('An unexpected error occurred');
  }
}

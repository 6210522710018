import { publicAPI } from './axios';

export async function checkGameStatus() {
  try {
    const response = await publicAPI.get('/check-status/');
    return response.data;
  } catch (error) {
    throw new Error('An unexpected error occured');
  }
}
